import React from 'react'
import './Squares.css'
function Squares() {
  return (
    <div className='sqrs'>
        <div className="upper-sqr">

        </div>
        <div className="lower-sqr">

        </div>
    </div>
  )
}

export default Squares