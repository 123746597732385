import React from 'react'
import "./Line.css"

function Line() {
  return (
    <div className='line-box'>
      <div className='line'></div>
    </div>
  )
}

export default Line